var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-8"},[_c('div',{staticClass:"container mx-auto"},[_vm._m(0),_c('div',{staticClass:"max-w-sm mx-auto md:max-w-full"},[_c('div',{staticClass:"pb-3"},[_c('carousel',{attrs:{"autoplay":true,"responsive":{
            0: { items: 1, nav: false, dots: false },
            700: { items: 3, nav: false },
            1000: { items: 4, nav: false },
          }}},_vm._l((_vm.certificate),function(item,i){return _c('div',{key:i},[_c('img',{staticClass:"px-2",attrs:{"src":item.image,"alt":item.alt}})])}),0)],1),_c('div',[_c('carousel',{attrs:{"autoplay":true,"responsive":{
            0: { items: 1, nav: false, dots: false },
            700: { items: 3, nav: false },
            1000: { items: 4, nav: false },
          }}},_vm._l((_vm.certificate_2),function(item,i){return _c('div',{key:i},[_c('img',{staticClass:"px-2",attrs:{"src":item.image,"alt":item.alt}})])}),0)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[_c('h1',{staticClass:"text-xl font-semibold leading-tight text-center text-blue-900"},[_vm._v(" Our Certificate ")])])}]

export { render, staticRenderFns }