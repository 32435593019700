<template>
  <div>
    <!-- main banner -->
    <div
      class="bg-no-repeat bg-cover bg-adjust"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <!-- language selectio -->
      <!-- <div class="container">
        <div class="flex justify-end py-1">
          <a href="" class="px-2 hover:text-ibl-purple">English</a> |
          <a href="/" class="px-2 hover:text-ibl-purple">Bahasa Malaysia</a>
        </div>
      </div> -->

      <div class="container py-5 lg:flex lg:justify-center">
        <img
          src="/images/banner-product.png"
          alt=""
          class="w-3/4 mx-auto md:w-1/2 lg:w-1/3 lg:order-2"
        />
        <!-- tagline -->
        <div class="hidden lg:block lg:w-1/2">
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-32 mx-auto lg:mx-0 md:w-52"
          />
          <h1
            class="pt-2 text-3xl font-bold leading-none text-center text-blue-900 uppercase lg:text-4xl lg:text-left"
          >
            We are dedicated Copier
            <span class="text-ibl-purple">Specialists.</span>
          </h1>
          <div
            class="py-4 text-base font-medium leading-tight text-center text-gray-600 lg:text-left"
          >
            <p class="pb-5">
              Affordable Copier Machine Rental in Kuala Lumpur & Selangor!
            </p>

            <div class="flex items-center pt-2 text-left">
            <div class="w-20">
            <img src="/images/touchngo.png" alt="" class="">
            </div>
            <p class="pl-2 text-base leading-tight">New order with <br> <span class="font-semibold text-red-900">RM168 touch n go ewallet reload</span></p>
          </div>
          </div>

          <!-- cta button -->
          <div
            v-if="!hideEnquiry"
            class="md:flex md:justify-center lg:justify-start"
          >
            <a
              href="#"
              class="block pb-1 md:px-1 md:pb-0"
              v-scroll-to="{ el: '#element', duration: 1500 }"
            >
              <h1
                class="py-2 mx-auto text-sm text-center text-white rounded-md w-44 md:border-2 md:border-ibl-purple bg-ibl-purple"
              >
                Enquire Us Now!
              </h1>
            </a>
            <a href="tel:+60380609449">
              <h1
                class="py-2 mx-auto text-sm text-center transition duration-300 ease-linear border-2 rounded-md hover:text-white hover:bg-ibl-purple text-ibl-purple w-44 border-ibl-purple"
              >
                Call Us 03-80609449
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile-tagline  -->
    <div class="py-6 lg:hidden bg-ibl-lite">
      <div class="container">
        <img src="/images/main-logo.png" alt="" class="w-32 mx-auto md:w-52" />
        <h1
          class="pt-2 text-3xl font-bold leading-none text-center text-blue-900 uppercase"
        >
          We are dedicated Copier
          <span class="text-ibl-purple">Specialists.</span>
        </h1>
        <div
          class="py-4 text-base font-medium leading-tight text-center text-gray-600"
        >
          <p class="pb-1">
            Affordable Copier Machine Rental in Kuala Lumpur & Selangor!
          </p>
          <div class="flex items-center justify-center pt-3 text-left">
            <div class="w-20">
            <img src="/images/touchngo.png" alt="" class="">
            </div>
            <p class="pl-2 text-sm leading-tight md:text-base">New order with <span class="text-red-900">RM168 touch n go ewallet reload</span></p>
          </div>
        </div>

        <!-- cta button -->
        <div class="md:flex md:justify-center">
          <a
            href="#"
            class="block pb-1 md:px-1 md:pb-0"
            v-scroll-to="{ el: '#element', duration: 1500 }"
          >
            <h1
              class="py-2 mx-auto text-sm text-center text-white rounded-md w-44 md:border-2 md:border-ibl-purple bg-ibl-purple"
            >
              Enquire Us Now!
            </h1>
          </a>
          <a href="tel:+60380609449">
            <h1
              class="py-2 mx-auto text-sm text-center transition duration-300 ease-linear border-2 rounded-md hover:text-white hover:bg-ibl-purple text-ibl-purple w-44 border-ibl-purple"
            >
              Call Us 03-80609449
            </h1>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
