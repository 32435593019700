<template>
  <div class="py-8">
    <div class="container mx-auto">
      <div class="pb-4">
        <h1 class="text-xl font-semibold leading-tight text-center text-blue-900">
           Our Certificate
        </h1>
      </div>
      <div class="max-w-sm mx-auto md:max-w-full">
        <div class="pb-3">
          <carousel
            :autoplay="true"
            :responsive="{
              0: { items: 1, nav: false, dots: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <div v-for="(item, i) in certificate" :key="i">
              <img :src="item.image" :alt="item.alt" class="px-2" />
            </div>
          </carousel>
        </div>
        <div>
          <carousel
            :autoplay="true"
            :responsive="{
              0: { items: 1, nav: false, dots: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <div v-for="(item, i) in certificate_2" :key="i">
              <img :src="item.image" :alt="item.alt" class="px-2" />
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },  
  data() {
    return {
      certificate: [
        { image: "/images/certi-1.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-2.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-4.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-5.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-6.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-7.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-8.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-9.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-10.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-11.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-12.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-13.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-14.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-15.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-16.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-17.jpg", alt: "IBL Solution Certificate" },
      ],
      certificate_2: [
        { image: "/images/certi-3.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-1.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-2.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-3.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-4.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-5.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-6.jpg", alt: "IBL Solution Certificate" },
        { image: "/images/certi-long-7.jpg", alt: "IBL Solution Certificate" },
      ],
    };
  },
};
</script>

<style>
</style>